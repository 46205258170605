<template>
  <div :class="['zform-checkbox', { 'zform-checkbox-inline': inline }, $attrs?.class]">
    <input
      v-bind="otherAttrs"
      :id="computedId"
      v-model="internalModel"
      :class="inputClasses"
      type="checkbox"
    >
    <label
      :class="['zform-checkbox-label']"
      :for="computedId"
    ><slot /></label>
  </div>
</template>

<script setup lang="ts">
import type { FormValue } from '~/types/style-guide'

defineOptions({
  inheritAttrs: false,
})

const { class: _, ...otherAttrs } = useAttrs()

const props = withDefaults(defineProps<{
  id?: string
  toggle?: boolean
  inline?: boolean
  large?: boolean
}>(), {
  toggle: false,
  inline: false,
  large: false,
})

const internalModel = defineModel<FormValue>()

const uuid = useId().replace('_', '-') // Temporary fix for pre 3.5+ usage of `useId()`
const computedId = computed(() => props.id || uuid)

const inputClasses = computed(() => {
  const type = props.toggle ? 'toggle' : 'box'
  return [
    'zform-checkbox-input',
    `zform-checkbox-${type}`,
    {
      [`zform-checkbox-${type}-large`]: props.large,
    },
  ]
})
</script>
